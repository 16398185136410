import React from 'react'
import Api from '../services/Api'

const api = new Api()
export default class Rapport extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            loading: true
        }
    }


    componentDidMount() {
        api.getRapports()
            .then(res => {
                this.setState({ data: res.data, loading: false })
            })
            .catch(err => { this.setState({ loading: false }) })
    }


    render() {
        const { data, loading } = this.state
        return (
            <div className="fade-in">
                <div className="row">
                    <div className="col-md-12 col-lg-12">
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">Titre</th>
                                    <th scope="col">Fichier</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            {loading === false &&
                                <tbody>
                                    {data.map((res, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{res.rapportname}</td>
                                                <td>{res.pdfpath}</td>
                                                <td>
                                                    <a href={`https://agcc2.mylogineo.com/${res.pdfpath}`} type="button" target="_blank" rel="noopener noreferrer" className="btn btn-outline-primary btn-sm mytooltip" >
                                                        <span className="tooltiptext">Télécharger</span>
                                                        <i className="fa fa-download" aria-hidden="true"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            }
                        </table>
                        {loading === true &&
                            <div className="myloader">
                                <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                        }
                    </div>

                </div>
            </div>
        );
    }
}