import React from 'react'
import Layout from './Layout'
import { Switch, BrowserRouter, Route } from 'react-router-dom'
import Tableau from './Tableau'
import Graphique from './Graphique'
import Carte from './Carte'
import Rapport from './Rapport'
import Filtre from '../components/Filtre'
import Api from '../services/Api'

const api = new Api()
export default class Application extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            region: false,
            cercle: false,
            commune: false,
            ong: false,
            action: false,

            loading: true,

            tempCommunes: [],
            tempCercles: [],
            tempOngs: [],

            communes: [],
            cercles: [],
            regions: [],
            ongs: [],
            suivis: [],
            tempSuivis: [],
            tableauSuivis: [],
            tableauTempSuivis: [],
            suivisApproved: [],
        }
    }

    componentDidMount() {
        api.getRegion()
        .then(rs => {
            const dt = rs.data.choices
            this.setState({ communes: dt.commune, cercles: dt.cercle, regions: dt.region, ongs: dt.pb9mo59 })

            api.get()
            .then(res => {
                const suivisApproved = res.data.filter(s => s['_validation_status']['uid'] === 'validation_status_approved')

                const suivis = Array.from(new Set(suivisApproved.map(a => a['gp_enqueteur/code_site'])))

                const gcSuivi = suivis.map(id => {
                    return suivisApproved.find(a => a['gp_enqueteur/code_site'] === id)
                })

                const  tableauSuivis = suivis.map(id=>{
                    return suivisApproved.filter(a => a['gp_enqueteur/code_site'] === id)
                })

                this.setState({ suivisApproved: suivisApproved, tempSuivis: gcSuivi, suivis: gcSuivi, tableauTempSuivis: tableauSuivis, tableauSuivis: tableauSuivis, loading: false })
            })
        })

        .catch(err => { console.log('ERROR===>', err) })
    }

    filterByDate(data, year) {
        const fil = data.filter(s => parseInt(s['gp_enqueteur/date_enquete'].split('-')[0]) === year.getFullYear())
        if (typeof (fil) !== 'undefined') { return fil }
        else { return [] }
    }
    filterByRegion(data, region) {
        const fil = data.filter(s => s['gp_localisation/region'] === region)
        if (typeof (fil) !== 'undefined') { return fil }
        else { return [] }
    }
    filterByCercle(data, cercle) {
        const fil = data.filter(s => s['gp_localisation/cercle'] === cercle)
        if (typeof (fil) !== 'undefined') { return fil }
        else { return [] }
    }
    filterByCommune(data, commune) {
        const fil = data.filter(s => s['gp_localisation/commune'] === commune)
        if (typeof (fil) !== 'undefined') { return fil }
        else { return [] }
    }
    filterByOng(data, ong) {
        const fil = data.filter(s => s['gp_enqueteur/nom_ong'] === ong)
        if (typeof (fil) !== 'undefined') { return fil }
        else { return [] }
    }

    myFilter(suivis, selectOng, selectCommune, selectCercle, selectRegion, year) {
        // const { year, selectRegion, selectCommune, selectCercle, selectOng } = this.state

        if (year !== null && year !== '') {                                     // check si les dates sont saisies
            const dateFiltre = this.filterByDate(suivis, year)                                      // filtrer par date
            // console.log("******* DATE FILTRE******** if******", dateFiltre)
            if (dateFiltre.length > 0) {                                                                  // check si le contenu du data n'est pas vide

                if (selectRegion !== null && selectRegion !== '') {                                       // check si une region est selectionnee
                    const regionFiltre = this.filterByRegion(dateFiltre, selectRegion)                      // filtrer par region
                    // console.log("******* DATE RGION****** if********", regionFiltre)
                    if (regionFiltre.length > 0) {                                                        // check si le contenu du data n'est pas vide

                        if (selectCercle !== null && selectCercle !== '') {                                       // check si une cercle est selectionnee
                            const cercleFiltre = this.filterByCercle(regionFiltre, selectCercle)                      // filtrer par cercle
                            // console.log("******* DATE Cercle******* if*******", cercleFiltre)
                            if (cercleFiltre.length > 0) {                                                        // check si le contenu du data n'est pas vide

                                if (selectCommune !== null && selectCommune !== '') {                                       // check si une cercle est selectionnee
                                    const communeFiltre = this.filterByCommune(cercleFiltre, selectCommune)                      // filtrer par commune
                                    // console.log("******* DATE communeFiltre******* if*******", communeFiltre)
                                    if (communeFiltre.length > 0) {                                                        // check si le contenu du data n'est pas vide

                                        if (selectOng !== null && selectOng !== '') {                                       // check si une cercle est selectionnee
                                            const ongFiltre = this.filterByOng(communeFiltre, selectOng)                      // filtrer par ong
                                            // console.log("******* DATE ongFiltre*******if*******", ongFiltre)
                                            if (ongFiltre.length > 0) {                                                        // check si le contenu du data n'est pas vide
                                                return ongFiltre
                                            } else {                                                                              // si le contenu du data est vide apres ongFiltre()
                                                return []                                                                       // retourner un tableau vide
                                            }
                                        }  //fin ong checking

                                        /***********************ONG NON RENSEIGNE************************************ */
                                        else {       // ONG n'est pas renseinge
                                            return communeFiltre
                                        }

                                    } else {                                                                              // si le contenu du data est vide apres communeFiltre()
                                        return []                                                                       // retourner un tableau vide
                                    }
                                }  //fin commune checking

                                /***********************COMMUNE NON RENSEIGNEE************************************ */
                                else {       // commune n'est pas renseingee
                                    return cercleFiltre
                                }

                            } else {                                                                              // si le contenu du data est vide apres cercleFiltre()
                                return []                                                                       // retourner un tableau vide
                            }
                        }  //fin cercle checking

                        /***********************CERCLE NON RENSEIGNE************************************ */
                        else {       // cercle n'est pas renseinge
                            return regionFiltre
                        }

                    } else {                                                                              // si le contenu du data est vide apres regionFiltre()
                        return []                                                                       // retourner un tableau vide
                    }
                }   //fin region checking

                /***********************REGION NON RENSEIGNEE************************************ */
                else {       // region n'est pas renseingee
                    return dateFiltre
                }

            } else {                                                                                      // si le contenu du data est vide apres dateFilter()
                return []                                                                       // retourner un tableau vide
            }


        } else {
            if (selectRegion !== null && selectRegion !== '') {
                const regionFiltre = this.filterByRegion(suivis, selectRegion)
                // console.log("******* filterByRegion******* ELSE*******", regionFiltre)
                if (regionFiltre.length > 0) {
                    if (selectCercle !== null && selectCercle !== '') {                                       // check si une cercle est selectionnee
                        const cercleFiltre = this.filterByCercle(regionFiltre, selectCercle)                      // filtrer par cercle
                        // console.log("******* cercleFiltre******* ELSE*******", cercleFiltre)
                        if (cercleFiltre.length > 0) {                                                        // check si le contenu du data n'est pas vide

                            if (selectCommune !== null && selectCommune !== '') {                                       // check si une cercle est selectionnee
                                const communeFiltre = this.filterByCommune(cercleFiltre, selectCommune)                      // filtrer par commune
                                // console.log("******* communeFiltre******* ELSE*******", communeFiltre)
                                if (communeFiltre.length > 0) {                                                        // check si le contenu du data n'est pas vide

                                    if (selectOng !== null && selectOng !== '') {                                       // check si une cercle est selectionnee
                                        const ongFiltre = this.filterByOng(communeFiltre, selectOng)                      // filtrer par ong
                                        // console.log("******* ongFiltre******* ELSE*******", ongFiltre)
                                        if (ongFiltre.length > 0) {                                                        // check si le contenu du data n'est pas vide
                                            return ongFiltre
                                        } else {                                                                              // si le contenu du data est vide apres ongFiltre()
                                            return []                                                                       // retourner un tableau vide
                                        }
                                    }  //fin ong checking

                                    /***********************ONG NON RENSEIGNE************************************ */
                                    else {       // ONG n'est pas renseinge
                                        return communeFiltre
                                    }

                                } else {                                                                              // si le contenu du data est vide apres communeFiltre()
                                    return []                                                                       // retourner un tableau vide
                                }
                            }  //fin commune checking

                            /***********************COMMUNE NON RENSEIGNEE************************************ */
                            else {       // commune n'est pas renseingee
                                if (selectOng !== null && selectOng !== '') {
                                    const ongFiltre = this.filterByOng(cercleFiltre, selectOng)
                                    // console.log("******* SI COMMUNE N'EST RENSEIGNE ONG********" + selectOng + "******", ongFiltre)
                                    return ongFiltre
                                } else {
                                    return cercleFiltre
                                }
                            }

                        } else {                                                                              // si le contenu du data est vide apres cercleFiltre()
                            return []                                                                       // retourner un tableau vide
                        }
                    }  //fin cercle checking

                    /***********************CERCLE NON RENSEIGNE************************************ */
                    else {       // cercle n'est pas renseinge
                        if (selectOng !== null && selectOng !== '') {
                            const ongFiltre = this.filterByOng(regionFiltre, selectOng)
                            // console.log("******* SI CERCLE N'EST RENSEIGNE ONG********" + selectOng + "******", ongFiltre)
                            return ongFiltre
                        } else {
                            return regionFiltre
                        }
                    }
                } else {
                    return []
                }
            } else {
                if (selectOng !== null && selectOng !== '') {
                    const ongFiltre = this.filterByOng(suivis, selectOng)
                    // console.log("******* DATE RIEN SELECTION SAUF ONG********" + selectOng + "******", ongFiltre)
                    return ongFiltre
                } else {
                    return suivis
                }
            }
        }
    }

    handleFilter(selectAction, selectOng, selectCommune, selectCercle, selectRegion, year) {
        const { /*selectAction,*/ tempSuivis, suivisApproved } = this.state
        // console.log("select===>", selectAction)
        if (selectAction !== null && selectAction !== '') {

            const s = tempSuivis.filter(f => f['type_activite'] === selectAction)
            // console.log("action filtre===>", s)
            if (typeof (s) !== 'undefined') {
                const suiviFilter = this.myFilter(s, selectOng, selectCommune, selectCercle, selectRegion, year)
                // console.log("my filtre===>", suiviFilter)
                const ss = Array.from(new Set(suiviFilter.map(a => a['gp_enqueteur/code_site'])))
                const  tableauSuivis = ss.map(id=>{
                    return suivisApproved.filter(a => a['gp_enqueteur/code_site'] === id)
                })

                this.setState({ suivis: suiviFilter, tableauSuivis })
            } else { this.setState({ suivis: [] }) }
        } else {
            const suiviFilter = this.myFilter(tempSuivis, selectOng, selectCommune, selectCercle, selectRegion, year)
            const ss = Array.from(new Set(suiviFilter.map(a => a['gp_enqueteur/code_site'])))
            const  tableauSuivis = ss.map(id=>{
                return suivisApproved.filter(a => a['gp_enqueteur/code_site'] === id)
            })
            // console.log(suiviFilter)
            this.setState({ suivis: suiviFilter, tableauSuivis })
        }
    }

    handleClearAll(){ this.setState({ suivis: this.state.tempSuivis, tableauSuivis: this.state.tableauTempSuivis }) }

    render() {
        const { ongs, communes, cercles, regions, suivis, tableauSuivis, loading } = this.state
        
        return (
            <div className="App">
                <div className="c-sidebar-brand">
                    <img className="c-sidebar-brand-full" src={require('../assets/img/logo.png')} alt="logo" width="140" />
                </div>
                {loading === true &&
                    <div className="myloader">
                        <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    </div>
                }
                {loading === false &&
                    <>
                        <div className="container-fluid">
                            <div className="fade-in">
                                <Filtre
                                    data={{ ongs: ongs, communes: communes, cercles: cercles, regions: regions }}
                                    onCancelAll={() => this.handleClearAll()}
                                    onFilter={(action, ong, commune, cercle, region, year) => this.handleFilter(action, ong, commune, cercle, region, year)}
                                />
                            </div>
                        </div>
                        <BrowserRouter>
                            <Layout />
                            <Switch>
                                <Route exact path='/' component={() => <Tableau data={{ suivis: tableauSuivis, communes, cercles, regions }} />} />
                                <Route exact path='/tableau' component={() => <Tableau data={{ suivis: tableauSuivis, communes, cercles, regions }} />} />
                                <Route exact path='/graphique' component={() => <Graphique response={{ data: {data_suivis: suivis, communes, cercles, regions} }} />} />
                                <Route exact path='/carte' component={() => <Carte data={ suivis } />} />
                                <Route exact path='/rapports' component={() => <Rapport data={ suivis } />} />
                            </Switch>
                        </BrowserRouter>
                    </>
                }
            </div>
        )

    }
}
