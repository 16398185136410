import React from 'react'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export'


export default class Tableau extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            region: false,
            cercle: false,
            commune: false,
            ong: false,
            action: false,

            loading: true,

            tempCommunes: [],
            tempCercles: [],
            tempOngs: [],

            communes: [],
            cercles: [],
            regions: [],
            ongs: [],
            suivis: [],
        }
    }

    typeAction(action) {
        if (action === 'lineaire') { return "Plantation linéaire" }
        if (action === 'surfacique') { return "Plantation en plein surfacique" }
        if (action === 'mise-defens') { return "Mise en défens" }
        if (action === 'maraichage') { return "Maraîchage" }
        if (action === 'rna') { return "RNA/Plantation Agroforestière" }
        if (action === 'drs-ces') { return "DRS/CES" }
    }

    txMarAug(data){
        let line = 0
        let tx = 0
        for(let i=0; i<data.length; i++) {
            const row = data[i]['gp_maraichage/mar_gp_especes/mar_repeat_gp_especes']
            for(let k=0; k<row.length; k++){
                if(i>0){
                    line = line +1
                    let pp = data[i-1]['gp_maraichage/mar_gp_especes/mar_repeat_gp_especes'][k]['gp_maraichage/mar_gp_especes/mar_repeat_gp_especes/mar_realisation_quantite']
                    let pc = row[k]['gp_maraichage/mar_gp_especes/mar_repeat_gp_especes/mar_realisation_quantite']
                    tx = tx  + (((parseInt(pc)-parseInt(pp))/parseInt(pp))*100)  
                }
            }
        }
        return line > 0 ? (tx/line).toFixed(2) : '-'
    }

    txMarRna(data){
        let line = 0
        let tx = 0
        for(let i=0; i<data.length; i++) {
            const row = data[i]['gp_rna/rna_gp_especes/rna_repeat_gp_especes']
            for(let k=0; k<row.length; k++){
                if(i>0){
                    line = line +1
                    let pp = data[i-1]['gp_rna/rna_gp_especes/rna_repeat_gp_especes'][k]['gp_rna/rna_gp_especes/rna_repeat_gp_especes/rna_realisation_plants']
                    let pc = row[k]['gp_rna/rna_gp_especes/rna_repeat_gp_especes/rna_realisation_plants']
                    tx = tx  + (((parseInt(pc)-parseInt(pp))/parseInt(pp))*100)  
                }
            }
        }
        return line > 0 ? (tx/line).toFixed(2) : '-'
    }

    txMarDrs(data){
        let line = 0
        let tx = 0
        for(let i=0; i<data.length; i++) {
            const row = data[i]['gp_drs_ces/drs_ces_gp_actions/drs_ces_repeat_gp_actions']
            for(let k=0; k<row.length; k++){
                if(i>0){
                    line = line +1
                    let pp = data[i-1]['gp_drs_ces/drs_ces_gp_actions/drs_ces_repeat_gp_actions'][k]['gp_drs_ces/drs_ces_gp_actions/drs_ces_repeat_gp_actions/drs_ces_realisation_nbr_ml']
                    let pc = row[k]['gp_drs_ces/drs_ces_gp_actions/drs_ces_repeat_gp_actions/drs_ces_realisation_nbr_ml']
                    tx = tx  + (((parseInt(pc)-parseInt(pp))/parseInt(pp))*100)  
                }
            }
        }
        return line > 0 ? (tx/line).toFixed(2) : '-'
    }

    txSurvi(data){
        const row = data[data.length - 1]['gp_plantation_lineaire/pl_gp_type_plantation/pl_repeat_gp_type_plantation']
        let somme = 0
        let inc = 0
        for (let i = 0; i < row.length; i++) {
            const line = row[i]['gp_plantation_lineaire/pl_gp_type_plantation/pl_repeat_gp_type_plantation/pl_gp_plant_mis_place/pl_repeat_gp_plant_mis_place']
            for (let j = 0; j < line.length; j++) {
                inc = inc + 1
                somme = somme + ((parseInt(line[j]['gp_plantation_lineaire/pl_gp_type_plantation/pl_repeat_gp_type_plantation/pl_gp_plant_mis_place/pl_repeat_gp_plant_mis_place/pl_nb_vivant'])/
                parseInt(line[j]['gp_plantation_lineaire/pl_gp_type_plantation/pl_repeat_gp_type_plantation/pl_gp_plant_mis_place/pl_repeat_gp_plant_mis_place/pl_realisation_nbr']).toFixed(2))*100)
            }
        }
        return inc > 0 ? (somme/inc).toFixed(2)  : ''
    }

    txSurfSurvi(data){
        const row = data[data.length - 1]['gp_plantation_surfacique/ps_gp_type_plantation/ps_repeat_gp_type_plantation']
        let somme = 0
        let inc = 0
        for (let i = 0; i < row.length; i++) {
            const line = row[i]['gp_plantation_surfacique/ps_gp_type_plantation/ps_repeat_gp_type_plantation/ps_gp_plant_mis_place/ps_repeat_gp_plant_mis_place']
            for (let j = 0; j < line.length; j++) {
                inc = inc + 1
                somme = somme + ((parseInt(line[j]['gp_plantation_surfacique/ps_gp_type_plantation/ps_repeat_gp_type_plantation/ps_gp_plant_mis_place/ps_repeat_gp_plant_mis_place/ps_nb_vivant'])/
                parseInt(line[j]['gp_plantation_surfacique/ps_gp_type_plantation/ps_repeat_gp_type_plantation/ps_gp_plant_mis_place/ps_repeat_gp_plant_mis_place/ps_realisation_nbr']).toFixed(2))*100)
            }
        }
        return inc > 0 ? (somme/inc).toFixed(2)  : ''
    }

    sumLineare(data, key) {
        const row = data[data.length - 1]['gp_plantation_lineaire/pl_gp_type_plantation/pl_repeat_gp_type_plantation']
        let somme = 0
        for (let i = 0; i < row.length; i++) {
            const line = row[i]['gp_plantation_lineaire/pl_gp_type_plantation/pl_repeat_gp_type_plantation/pl_gp_plant_mis_place/pl_repeat_gp_plant_mis_place']
            for (let j = 0; j < line.length; j++) {
                somme = somme + parseInt(line[j][key])
            }
        }
        return somme
    }

    sumSurfacique(data, key) {
        const row = data[data.length - 1]['gp_plantation_surfacique/ps_gp_type_plantation/ps_repeat_gp_type_plantation']
        let somme = 0
        for (let i = 0; i < row.length; i++) {
            const line = row[i]['gp_plantation_surfacique/ps_gp_type_plantation/ps_repeat_gp_type_plantation/ps_gp_plant_mis_place/ps_repeat_gp_plant_mis_place']
            for (let j = 0; j < line.length; j++) {
                somme = somme + parseInt(line[j][key])
            }
        }
        return somme
    }

    sum(data,key) { return data.reduce((a, b) => parseInt(a) + (parseInt(b[key]) || 0), 0) }

    componentDidMount() {
        const { data } = this.props
        const suivis = data.suivis
        const regions = data.regions
        const cercles = data.cercles
        const communes = data.communes
        const mysuivis = []

        for (let i = 0; i < suivis.length; i++) {
            let rg = regions.find(r => r['name'] === suivis[i][0]['gp_localisation/region'])
            let cr = cercles.find(r => r['name'] === suivis[i][0]['gp_localisation/cercle'])
            let cm = communes.find(r => r['name'] === suivis[i][0]['gp_localisation/commune'])

            let tg_survi = 0
            let tg_realisation = 0
            let tg_augmentation = 0

            let grillage = '-'
            let long_grillage = '-'
            let point_eau = '-'
            let nb_point_eau = '-'
            let lequel_point_eau = '-'
            let mise_defens = '-'
            let panneaux = '-'
            let panneau = '-'
            let commentaires = '-'
            let bornee = '-'
            let bornes = '-'
            let gardien = '-'
            let gardiens = '-'
            let surperficie = '-'
            let code_gps = '-'
            
            if(suivis[i][0]['type_activite'] === "drs-ces" ){

                mise_defens = suivis[i][0]['gp_drs_ces/group_hq4wc41/drs_ces_dans_mise_defens']
                panneaux = suivis[i][0]['gp_drs_ces/group_hq4wc41/drs_ces_nb_panneaux']
                panneau = suivis[i][0]['gp_drs_ces/group_hq4wc41/drs_ces_panneau']
                surperficie = suivis[i][0]['gp_drs_ces/group_hq4wc41/drs_ces_superficie_ha']
                commentaires = suivis[i][0]['gp_drs_ces/group_hq4wc41/drs_ces_commentaires']
                code_gps = suivis[i][0]['gp_drs_ces/group_hq4wc41/drs_ces_oui_code_gps_md']

                tg_augmentation = this.txMarDrs(suivis[i])
                tg_survi = '-'
                tg_realisation = ((this.sum(suivis[i][suivis[i].length -1]['gp_drs_ces/drs_ces_gp_actions/drs_ces_repeat_gp_actions'], 'gp_drs_ces/drs_ces_gp_actions/drs_ces_repeat_gp_actions/drs_ces_realisation_nbr_ml') /
                this.sum(suivis[i][suivis[i].length -1]['gp_drs_ces/drs_ces_gp_actions/drs_ces_repeat_gp_actions'], 'gp_drs_ces/drs_ces_gp_actions/drs_ces_repeat_gp_actions/drs_ces_prevision_nbr_ml'))*100).toFixed(2)
            }

            if(suivis[i][0]['type_activite'] === "maraichage" ){

                grillage = suivis[i][0]['gp_maraichage/group_ta5tp86/mar_grillage']
                long_grillage = suivis[i][0]['gp_maraichage/group_ta5tp86/mar_long_grillage']
                gardien = suivis[i][0]['gp_maraichage/group_ta5tp86/mar_gardien']
                gardiens = suivis[i][0]['gp_maraichage/group_ta5tp86/mar_nb_gardiens']
                point_eau = suivis[i][0]['gp_maraichage/group_ta5tp86/mar_point_eau']
                nb_point_eau = suivis[i][0]['gp_maraichage/group_ta5tp86/mar_nb_point_eau']
                lequel_point_eau = suivis[i][0]['gp_maraichage/group_ta5tp86/mar_lequel_point_eau']
                panneaux = suivis[i][0]['gp_maraichage/group_ta5tp86/mar_nb_panneaux']
                panneau = suivis[i][0]['gp_maraichage/group_ta5tp86/mar_panneau']
                surperficie = suivis[i][0]['gp_maraichage/group_ta5tp86/mar_superficie_ha']
                commentaires = suivis[i][0]['gp_maraichage/group_ta5tp86/mar_commentaires']

                tg_augmentation = this.txMarAug(suivis[i])
                tg_survi = '-'
                tg_realisation = ((this.sum(suivis[i][suivis[i].length -1]['gp_maraichage/mar_gp_especes/mar_repeat_gp_especes'], 'gp_maraichage/mar_gp_especes/mar_repeat_gp_especes/mar_realisation_quantite') /
                this.sum(suivis[i][suivis[i].length -1]['gp_maraichage/mar_gp_especes/mar_repeat_gp_especes'], 'gp_maraichage/mar_gp_especes/mar_repeat_gp_especes/mar_prevision_quantite'))*100).toFixed(2)
            }

            if(suivis[i][0]['type_activite'] === "rna" ){

                grillage = suivis[i][0]['gp_rna/gp_rna_infos/rna_grillage']
                long_grillage = suivis[i][0]['gp_rna/gp_rna_infos/rna_long_grillage']
                panneaux = suivis[i][0]['gp_rna/gp_rna_infos/rna_nb_panneaux']
                panneau = suivis[i][0]['gp_rna/gp_rna_infos/rna_panneau']
                surperficie = suivis[i][0]['gp_rna/gp_rna_infos/rna_superficie_ha']
                commentaires = suivis[i][0]['gp_rna/gp_rna_infos/rna_commentaires']

                tg_augmentation = this.txMarRna(suivis[i])
                tg_survi = '-'
                tg_realisation = ((this.sum(suivis[i][suivis[i].length -1]['gp_rna/rna_gp_especes/rna_repeat_gp_especes'], 'gp_rna/rna_gp_especes/rna_repeat_gp_especes/rna_realisation_plants') /
                this.sum(suivis[i][suivis[i].length -1]['gp_rna/rna_gp_especes/rna_repeat_gp_especes'], 'gp_rna/rna_gp_especes/rna_repeat_gp_especes/rna_prevision_plants'))*100).toFixed(2)
            }

            if(suivis[i][0]['type_activite'] === "mise-defens" ){

                panneaux = suivis[i][0]['gp_mise_defens/md_gp_infos/md_nb_panneaux']
                panneau = suivis[i][0]['gp_mise_defens/md_gp_infos/md_panneau']
                bornee = suivis[i][0]['gp_mise_defens/md_gp_infos/md_bornee']
                bornes = suivis[i][0]['gp_mise_defens/md_gp_infos/md_nb_bornes']
                gardien = suivis[i][0]['gp_mise_defens/md_gp_infos/md_gardien']
                gardiens = suivis[i][0]['gp_mise_defens/md_gp_infos/md_nb_gardiens']
                surperficie = suivis[i][0]['gp_mise_defens/md_gp_infos/md_superficie_ha']
                commentaires = suivis[i][0]['gp_mise_defens/md_gp_infos/md_commentaires']

                tg_survi = '-'
                tg_realisation = '-'
                tg_augmentation = '-'
            }

            if(suivis[i][0]['type_activite'] === "lineaire" ){

                grillage = suivis[i][0]['gp_plantation_lineaire/group_hm1ce84/pl_grillage']
                long_grillage = suivis[i][0]['gp_plantation_lineaire/group_hm1ce84/pl_long_grillage']
                point_eau = suivis[i][0]['gp_plantation_lineaire/group_hm1ce84/pl_point_eau']
                nb_point_eau = suivis[i][0]['gp_plantation_lineaire/group_hm1ce84/pl_nb_point_eau']
                lequel_point_eau = suivis[i][0]['gp_plantation_lineaire/group_hm1ce84/pl_lequel_point_eau']
                mise_defens = suivis[i][0]['gp_plantation_lineaire/group_hm1ce84/pl_dans_mise_defens']
                panneaux = suivis[i][0]['gp_plantation_lineaire/group_hm1ce84/pl_nb_panneaux']
                panneau = suivis[i][0]['gp_plantation_lineaire/group_hm1ce84/pl_panneau']
                commentaires = suivis[i][0]['gp_plantation_lineaire/group_hm1ce84/pl_commentaires']

                tg_survi = this.txSurvi(suivis[i])
                tg_augmentation = '-'
                tg_realisation = ((this.sumLineare(suivis[i], 'gp_plantation_lineaire/pl_gp_type_plantation/pl_repeat_gp_type_plantation/pl_gp_plant_mis_place/pl_repeat_gp_plant_mis_place/pl_realisation_nbr') /
                this.sumLineare(suivis[i], 'gp_plantation_lineaire/pl_gp_type_plantation/pl_repeat_gp_type_plantation/pl_gp_plant_mis_place/pl_repeat_gp_plant_mis_place/pl_prevision_nbr'))*100).toFixed(2)
            }

            if(suivis[i][0]['type_activite'] === "surfacique" ){

                grillage = suivis[i][0]['gp_plantation_surfacique/group_ps6oc99/ps_grillage']
                long_grillage = suivis[i][0]['gp_plantation_surfacique/group_ps6oc99/ps_long_grillage']
                point_eau = suivis[i][0]['gp_plantation_surfacique/group_ps6oc99/ps_point_eau']
                nb_point_eau = suivis[i][0]['gp_plantation_surfacique/group_ps6oc99/ps_nb_point_eau']
                lequel_point_eau = suivis[i][0]['gp_plantation_surfacique/group_ps6oc99/ps_lequel_point_eau']
                mise_defens = suivis[i][0]['gp_plantation_surfacique/group_ps6oc99/ps_dans_mise_defens']
                panneaux = suivis[i][0]['gp_plantation_surfacique/group_ps6oc99/ps_nb_panneaux']
                panneau = suivis[i][0]['gp_plantation_surfacique/group_ps6oc99/ps_panneau']
                commentaires = suivis[i][0]['gp_plantation_surfacique/group_ps6oc99/ps_commentaires']

                tg_survi = this.txSurfSurvi(suivis[i])
                tg_augmentation = '-'
                tg_realisation = ((this.sumSurfacique(suivis[i], 'gp_plantation_surfacique/ps_gp_type_plantation/ps_repeat_gp_type_plantation/ps_gp_plant_mis_place/ps_repeat_gp_plant_mis_place/ps_realisation_nbr') /
                this.sumSurfacique(suivis[i], 'gp_plantation_surfacique/ps_gp_type_plantation/ps_repeat_gp_type_plantation/ps_gp_plant_mis_place/ps_repeat_gp_plant_mis_place/ps_prevision_nbr'))*100).toFixed(2)
            }


            mysuivis[i] = {
                code: suivis[i][0]['gp_enqueteur/code_site'],
                date: this.dateFormat(suivis[i][0]['gp_enqueteur/date_enquete']),
                ong: suivis[i][0]['gp_enqueteur/nom_ong'],
                village: suivis[i][0]['gp_localisation/village'],
                commune: typeof(cm) !== 'undefined' ? cm.label : suivis[i][0]['gp_localisation/commune'],
                cercle: typeof(cr) !== 'undefined' ? cr.label : suivis[i][0]['gp_localisation/cercle'],
                region: typeof(rg) !== 'undefined' ? rg.label : suivis[i][0]['gp_localisation/region'],
                activite: this.typeAction(suivis[i][0]['type_activite']),
                realisation: tg_realisation,
                survi: tg_survi,
                augmentation: tg_augmentation,
                lat: suivis[i][0]['_geolocation'][0],
                lng: suivis[i][0]['_geolocation'][1],

                hameau: suivis[i][0]['gp_localisation/hameau'],
                nom_local_zone: suivis[i][0]['gp_localisation/nom_local_zone'],
                nom_foret_communautaire: suivis[i][0]['gp_localisation/nom_foret_communautaire'],
                distance_village_km: suivis[i][0]['gp_localisation/distance_village_km'],

                prenom_nom_enqueteur: suivis[i][0]['gp_enqueteur/prenom_nom_enqueteur'],
                contact_telephonique_enqueteur: suivis[i][0]['gp_enqueteur/contact_telephonique_enqueteur'],
                service_enqueteur: suivis[i][0]['gp_enqueteur/service_enqueteur'],
                fonction_enqueteur: suivis[i][0]['gp_enqueteur/fonction_enqueteur'],
                nom_interlocuteur: suivis[i][0]['gp_enqueteur/nom_interlocuteur'],
                fonction_interlocuteur: suivis[i][0]['gp_enqueteur/fonction_interlocuteur'],

                grillage,
                long_grillage,
                point_eau,
                nb_point_eau,
                lequel_point_eau,
                mise_defens,
                panneaux,
                panneau,
                commentaires,
                bornee,
                bornes,
                gardien,
                gardiens,
                surperficie,
                code_gps,


            }
        }
        this.setState({ tempSuivis: mysuivis, suivis: mysuivis })
    }

    dateFormat(date) {
        const d = date.split('-')
        return `${d[2]}-${d[1]}-${d[0]}`
    }

    _exporter;
    export = () => {
        this._exporter.save();
    }

    printDocument() {
        const input = document.getElementById('divToPrint');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF()

                pdf.addImage(imgData, "JPEG", 5, 5, 200, 0);
                pdf.save("Tableau.pdf");
            })
    }

    render() {
        const { suivis } = this.state

        return (
            <div className="fade-in">
                <div className="row">
                    <div className="col-md-12 col-lg-12" style={{ marginBottom: 12 }}>
                        <div style={{ float: 'right' }}>
                            <button type="button" className="btn btn-outline-primary btn-sm mytooltip" style={{ marginRight: 8 }} onClick={this.export}>
                                <span className="tooltiptext">Exporter en excel</span>
                                <i className="fa fa-file-excel-o" aria-hidden="true"></i>
                            </button>
                            <button type="button" className="btn btn-outline-primary btn-sm mytooltip" onClick={() => this.printDocument()}>
                                <span className="tooltiptext">Exporter en pdf</span>
                                <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                    <div className="col-md-12 col-lg-12" id="divToPrint">
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th scope="col">Code</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">ONG</th>
                                    <th scope="col">Village</th>
                                    <th scope="col">Commune</th>
                                    <th scope="col">Cercle</th>
                                    <th scope="col">Région</th>
                                    <th scope="col">Type d'action</th>
                                    <th scope="col">Taux d'augmentation(%)</th>
                                    <th scope="col">Taux de survi(%)</th>
                                    <th scope="col">Réalisation globale(%)</th>
                                    <th scope="col">Latitude</th>
                                    <th scope="col">Logintude</th>
                                </tr>
                            </thead>
                            <tbody>
                                {suivis.length > 0 &&
                                    suivis.map((r, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>{r.code}</td>
                                                <td>{r.date}</td>
                                                <td>{r.ong}</td>
                                                <td>{r.village}</td>
                                                <td>{r.commune}</td>
                                                <td>{r.cercle}</td>
                                                <td>{r.region}</td>
                                                <td>{r.activite}</td>

                                                <td>{r.augmentation}</td>
                                                <td>{r.survi}</td>
                                                <td>{r.realisation}</td>

                                                <td>{r.lat}</td>
                                                <td>{r.lng}</td>
                                            </tr>
                                        )
                                    })}
                            </tbody>
                        </table>
                    </div>

                    <ExcelExport data={suivis} fileName="Tableau.xlsx" ref={(exporter) => { this._exporter = exporter; }}>
                        <ExcelExportColumn field="code" title="Code site" />
                        <ExcelExportColumn field="date" title="Date" />
                        <ExcelExportColumn field="ong" title="ONG" />
                        <ExcelExportColumn field="village" title="Village" />

                        <ExcelExportColumn field="hameau" title="Hameau" />
                        <ExcelExportColumn field="nom_local_zone" title="Nom local de la zone" />
                        <ExcelExportColumn field="nom_foret_communautaire" title="Nom forêt communautaire" />
                        <ExcelExportColumn field="distance_village_km" title="Distance/Village (Km)" />

                        <ExcelExportColumn field="commune" title="Commune" />
                        <ExcelExportColumn field="cercle" title="Cercle" />
                        <ExcelExportColumn field="region" title="Région" />

                        <ExcelExportColumn field="prenom_nom_enqueteur" title="Enqueteur" />
                        <ExcelExportColumn field="contact_telephonique_enqueteur" title="Contact téléphonique de l'enquêteur" />
                        <ExcelExportColumn field="service_enqueteur" title="Service de l'enquêteur" />
                        <ExcelExportColumn field="fonction_enqueteur" title="Fonction de l'enquêteur" />
                        <ExcelExportColumn field="nom_interlocuteur" title="Nom de l'interlocuteur" />
                        <ExcelExportColumn field="fonction_interlocuteur" title="Fonction de l'interlocuteur" />

                        <ExcelExportColumn field="activite" title="Type d'action" />
                        <ExcelExportColumn field="augmentation" title="Taux d'augmentation(%)" />
                        <ExcelExportColumn field="survi" title="Taux de survi(%)" />
                        <ExcelExportColumn field="realisation" title="Taux de réalisation globale(%)" />

                        <ExcelExportColumn field="['grillage']" title="Grillage ?" />
                        <ExcelExportColumn field="['long_grillage']" title="Longueur du grillage(m)" />
                        <ExcelExportColumn field="['point_eau']" title="Point d'eau ?" />
                        <ExcelExportColumn field="['nb_point_eau']" title="Nombre de point d'eau " />
                        <ExcelExportColumn field="['lequel_point_eau']" title="Les differnts points d'eau " />
                        <ExcelExportColumn field="['mise_defens']" title="Superficie de la mise en défens (ha)" />
                        <ExcelExportColumn field="['panneau']" title="Panneau ?" />
                        <ExcelExportColumn field="['panneaux']" title="Nombre de panneaux" />
                        <ExcelExportColumn field="['gardien']" title="Gardien ?" />
                        <ExcelExportColumn field="['gardiens']" title="Nombre de gardiens" />
                        <ExcelExportColumn field="['bornee']" title="Bornée ?" />
                        <ExcelExportColumn field="['bornes']" title="Nombre de bornes" />
                        <ExcelExportColumn field="['code_gps']" title="Code GPS" />
                        <ExcelExportColumn field="['surperficie']" title="Surperficie (ha)" />
                        <ExcelExportColumn field="['commentaires']" title="Commentaires" />

                        <ExcelExportColumn field="lat" title="Latitude" />
                        <ExcelExportColumn field="lng" title="Logintude" />
                    </ExcelExport>
                </div>
            </div>
        );
    }
}