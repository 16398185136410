import React from 'react'
import DatePicker from 'react-datepicker'
import '../assets/css/react-datepicker.css'

export default class Filtre extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            region: false,
            cercle: false,
            commune: false,
            ong: false,
            action: false,

            communes: [], tempCommunes: [], cercles: [], tempCercles: [], regions: [], ongs: [],

            //filtre
            yearSelected:"Année",
            year: "",
            selectRegion: '',
            selectCommune: '',
            selectCercle: '',
            selectOng: '',
            selectAction: ''
        }
        this.yearChange = this.yearChange.bind(this);
    }

    componentDidMount(){
        const { data } = this.props
        this.setState({ tempCommunes: data.communes, tempCercles: data.cercles, regions: data.regions, ongs: data.ongs })
    }

    yearChange(value) {  this.setState({ year: value }) }

    regionChange(value) {
        const { tempCercles } = this.state
        const cercles = tempCercles.filter(c => c.region === value)
        this.setState({ cercles, selectRegion: value })
    }

    cercleChange(value) {
        const { tempCommunes } = this.state
        const communes = tempCommunes.filter(c => c.cercle === value)
        this.setState({ communes, selectCercle: value })
    }

    showFilter(filtre) {
        const { region, cercle, commune } = this.state
        if (filtre === 'commune' && region === false && cercle === false && commune === false) {
            this.setState({ region: true, cercle: true, commune: true })
        } else {
            if (filtre === 'cercle' && region === false) {
                this.setState({ region: true, cercle: true })
            } else {
                if (filtre === 'region' && region === true) {
                    this.setState({ region: false, cercle: false, commune: false })
                } else {
                    this.setState({ [filtre]: !this.state[filtre] })
                }
            }
        }

    }

    handleFilter() {
        const { selectAction, selectOng, selectCommune, selectCercle, selectRegion, year/*, tempSuivis */} = this.state
        this.props.onFilter(selectAction, selectOng, selectCommune, selectCercle, selectRegion, year)
    }

    clearAll() {
        this.props.onCancelAll()
        this.setState({ year: '', selectRegion: '', selectCercle: '', selectCommune: '', selectOng: '', selectAction: '' })
    }

    render() {

        const { region, commune, cercle, ong, action, regions, communes, cercles, ongs,/* suivis,
            plantsPrevus, surperficie, borne, grillage, panneau, gardien, nombreSite, plantsVivants,
            lastSite, sitesData, rna, defens, maraichage, drs, lineaire, surfacique,*/
            year, selectRegion, selectCercle, selectCommune, selectOng, selectAction } = this.state

        return (
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header" style={{ paddingBottom: 0 }}>Filtrer</div>
                        <div className="card-body" style={{ paddingTop: 8 }}>
                            <div className="row">
                                <div className="col-12" style={{ marginBottom: 8 }}>
                                    <div className="btn-group float-left">
                                        {region && <select className="form-control" style={{ marginRight: 8 }} value={selectRegion} onChange={(e) => { this.regionChange(e.target.value) }}>
                                            <option value="">Région</option>
                                            {regions.map((r, i) => {
                                                return <option value={r.name} key={i}>{r.label}</option>
                                            })}
                                        </select>}
                                        {cercle && <select className="form-control" style={{ marginRight: 8 }} value={selectCercle} onChange={(e) => { this.cercleChange(e.target.value) }}>
                                            <option value="">Cercle</option>
                                            {cercles.map((r, i) => {
                                                return <option value={r.name} key={i}>{r.label}</option>
                                            })}
                                        </select>}
                                        {commune && <select className="form-control" style={{ marginRight: 8 }} value={selectCommune} onChange={(e) => this.setState({ selectCommune: e.target.value })} >
                                            <option value="">Commune</option>
                                            {communes.map((r, i) => {
                                                return <option value={r.name} key={i}>{r.label}</option>
                                            })}
                                        </select>}
                                        {ong && <select className="form-control" value={selectOng} onChange={(e) => this.setState({ selectOng: e.target.value })}>
                                            <option value="">ONG</option>
                                            {ongs.map((r, i) => {
                                                return <option value={r.name} key={i}>{r.label}</option>
                                            })}
                                        </select>}
                                        {action && <select className="form-control" value={selectAction} onChange={(e) => this.setState({ selectAction: e.target.value })}>
                                            <option value="">Type d'action</option>
                                            <option value="lineaire">Plantation linéaire</option>
                                            <option value="surfacique">Plantation en plein surfacique</option>
                                            <option value="mise-defens">Mise en défens</option>
                                            <option value="maraichage">Maraîchage</option>
                                            <option value="rna">RNA/Plantation Agroforestière</option>
                                            <option value="drs-ces">DRS/CES</option>
                                        </select>}
                                    </div>
                                    <div className="btn-group float-right">
                                        <label style={{ margin: '4px 12px' }}>Année</label>
                                        {/* <input type="date" className="form-control" style={{ height: 32 }} value={from} onChange={(date) => this.setState({ from: date.target.value })} />
    
                                                    <label style={{ margin: '4px 12px' }}>À</label>
                                                    <input type="date" className="form-control" style={{ height: 32 }} value={to} onChange={(date) => this.setState({ to: date.target.value })} /> */}
                                        <div style={{ width: 120, marginRight: 100 }} className="float-right">
                                            <DatePicker
                                                className="form-control mydatepicker"
                                                placeholderText="Toutes les données"
                                                selected={year}
                                                onSelect={this.yearChange}
                                                showYearPicker
                                                dateFormat="yyyy"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 float-left">
                                    <button className="btn btn-secondary btn-sm" style={{ marginRight: 8, paddingRight: 8 }} id="filterDropDown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="material-icons" style={{ margin: 0, top: 3, position: 'relative', fontSize: 16 }}>add</i>
                                    </button>
                                    <div className="dropdown-menu" aria-labelledby="filterDropDown">
                                        <li className="dropdown-item" onClick={() => this.showFilter('region')}>Région</li>
                                        <li className="dropdown-item" onClick={() => this.showFilter('cercle')}>Cercle</li>
                                        <li className="dropdown-item" onClick={() => this.showFilter('commune')}>Commune</li>
                                        <li className="dropdown-item" onClick={() => this.showFilter('ong')}>ONG</li>
                                        <li className="dropdown-item" onClick={() => this.showFilter('action')}>Type d'action</li>
                                    </div>
                                    <button type="button" className="btn btn-info btn-sm" style={{ marginRight: 8 }} onClick={() => this.handleFilter()}> Appliquer </button>
                                    <button type="button" className="btn btn-secondary btn-sm" onClick={() => this.clearAll()}>Annuler</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}