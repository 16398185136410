import React from 'react'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { /*Doughnut, Bar, Line, */Pie } from 'react-chartjs-2';


export default class Graphique extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            data: [],
            list_ong: [],
            nb_site: '',
            submission: [],
            geolocation: [],

            regions: [],
            cercles: [],

            regionSelected: 'kayes',
            cercleSelected: 'kayes',
            newCercles: [],
            newCommunes: [],

            type_activites: [],

            data_suivis: [],
            data_suivis_region: [],
            data_suivis_cercle: [],
            data_suivis_commune: [],

            lineaire_region: '',
            surfacique_region: '',
            mise_defens_region: '',
            maraichage_region: '',
            rna_region: '',
            drs_ces_region: '',

            lineaire_cercle: '',
            surfacique_cercle: '',
            mise_defens_cercle: '',
            maraichage_cercle: '',
            rna_cercle: '',
            drs_ces_cercle: '',

            lineaire_commune: '',
            surfacique_commune: '',
            mise_defens_commune: '',
            maraichage_commune: '',
            rna_commune: '',
            drs_ces_commune: ''

        }
    }

    componentDidMount() {
        const { response } = this.props

        this.setState({
            data: response.data,
            list_ong: response.data.list_ong,
            nb_site: response.data.nb_site,
            submission: response.data.submission,
            geolocation: response.data.geolocation,

            regions: response.data.regions,
            cercles: response.data.cercles,
            communes: response.data.communes,

            newCercles: response.data.cercles.filter(c => c.region === 'kayes'),
            newCommunes: response.data.cercles.filter(c => c.cercle === 'kayes'),

            type_activites: response.data.type_activites,

            data_suivis: response.data.data_suivis.filter(c => c['_validation_status']['uid'] === 'validation_status_approved'),

            data_suivis_region: response.data.data_suivis.filter(c => c['gp_localisation/region'] === 'kayes'),
            data_suivis_cercle: response.data.data_suivis.filter(c => c['gp_localisation/cercle'] === 'kayes'),
            // data_suivis_commune: response.data.data_suivis.filter(c => c['gp_localisation/commune'] === 'kayes')
        });

        const data_suivis = response.data.data_suivis.filter(c => c['_validation_status']['uid'] === 'validation_status_approved');

        this.setState({
            data_suivis_region: data_suivis.filter(c => c['gp_localisation/region'] === 'kayes'),
            data_suivis_cercle: data_suivis.filter(c => c['gp_localisation/cercle'] === 'kayes'),
            data_suivis_commune: data_suivis.filter(c => c['gp_localisation/commune'] === 'kayes')
        })

        const lineaire = this.state.data_suivis_region.filter(c => c['type_activite'] === 'lineaire').length;
        const surfacique = this.state.data_suivis_region.filter(c => c['type_activite'] === 'surfacique').length;
        const mise_defens = this.state.data_suivis_region.filter(c => c['type_activite'] === 'mise-defens').length;
        const maraichage = this.state.data_suivis_region.filter(c => c['type_activite'] === 'maraichage').length;
        const rna = this.state.data_suivis_region.filter(c => c['type_activite'] === 'rna').length;
        const drs_ces = this.state.data_suivis_region.filter(c => c['type_activite'] === 'drs-ces').length;

        const total = lineaire + surfacique + mise_defens + maraichage + rna + drs_ces;

        const lineaires = (lineaire * 100) / total;
        const surfaciques = (surfacique * 100) / total;
        const mise_defenss = (mise_defens * 100) / total;
        const maraichages = (maraichage * 100) / total;
        const rnas = (rna * 100) / total;
        const drs_cess = (drs_ces * 100) / total;

        this.setState({
            lineaire_region: lineaires,
            surfacique_region: surfaciques,
            mise_defens_region: mise_defenss,
            maraichage_region: maraichages,
            rna_region: rnas,
            drs_ces_region: drs_cess,

            lineaire_cercle: lineaires,
            surfacique_cercle: surfaciques,
            mise_defens_cercle: mise_defenss,
            maraichage_cercle: maraichages,
            rna_cercle: rnas,
            drs_ces_cercle: drs_cess,

            lineaire_commune: lineaires,
            surfacique_commune: surfaciques,
            mise_defens_commune: mise_defenss,
            maraichage_commune: maraichages,
            rna_commune: rnas,
            drs_ces_commune: drs_cess,

            loading: false
        });

    }

    regionChange(value) {
        this.setState({ regionSelected: value });

        const cercles = this.state.cercles;

        const cercle = cercles.filter(c => c.region === value)

        this.setState({ newCercles: cercle });

        const data_suivis_region = this.state.data_suivis.filter(c => c['gp_localisation/region'] === value);
        // const data_suivis_cercle = this.state.data_suivis.filter(c => c['gp_localisation/cercle'] === value);

        const lineaire = data_suivis_region.filter(c => c['type_activite'] === 'lineaire').length;
        const surfacique = data_suivis_region.filter(c => c['type_activite'] === 'surfacique').length;
        const mise_defens = data_suivis_region.filter(c => c['type_activite'] === 'mise-defens').length;
        const maraichage = data_suivis_region.filter(c => c['type_activite'] === 'maraichage').length;
        const rna = data_suivis_region.filter(c => c['type_activite'] === 'rna').length;
        const drs_ces = data_suivis_region.filter(c => c['type_activite'] === 'drs-ces').length;

        const total = lineaire + surfacique + mise_defens + maraichage + rna + drs_ces;

        const lineaires = ((lineaire * 100) / total).toFixed(2)
        const surfaciques = ((surfacique * 100) / total).toFixed(2)
        const mise_defenss = ((mise_defens * 100) / total).toFixed(2)
        const maraichages = ((maraichage * 100) / total).toFixed(2)
        const rnas = ((rna * 100) / total).toFixed(2)
        const drs_cess = ((drs_ces * 100) / total).toFixed(2)

        this.setState({
            lineaire_region: lineaires,
            surfacique_region: surfaciques,
            mise_defens_region: mise_defenss,
            maraichage_region: maraichages,
            rna_region: rnas,
            drs_ces_region: drs_cess,
            // lineaire_cercle: lineaires,
            // surfacique_cercle: surfaciques,
            // mise_defens_cercle: mise_defenss,
            // maraichage_cercle: maraichages,
            // rna_cercle: rnas,
            // drs_ces_cercle: drs_cess
        });

    }

    cercleChange(value) {
        this.setState({ cercleSelected: value });

        const communes = this.state.communes;

        const commune = communes.filter(c => c.cercle === value)

        this.setState({ newCommunes: commune });

        const data_suivis_cercle = this.state.data_suivis.filter(c => c['gp_localisation/cercle'] === value);

        const lineaire = data_suivis_cercle.filter(c => c['type_activite'] === 'lineaire').length;
        const surfacique = data_suivis_cercle.filter(c => c['type_activite'] === 'surfacique').length;
        const mise_defens = data_suivis_cercle.filter(c => c['type_activite'] === 'mise-defens').length;
        const maraichage = data_suivis_cercle.filter(c => c['type_activite'] === 'maraichage').length;
        const rna = data_suivis_cercle.filter(c => c['type_activite'] === 'rna').length;
        const drs_ces = data_suivis_cercle.filter(c => c['type_activite'] === 'drs-ces').length;

        const total = lineaire + surfacique + mise_defens + maraichage + rna + drs_ces;

        const lineaires = ((lineaire * 100) / total).toFixed(2)
        const surfaciques = ((surfacique * 100) / total).toFixed(2)
        const mise_defenss = ((mise_defens * 100) / total).toFixed(2)
        const maraichages = ((maraichage * 100) / total).toFixed(2)
        const rnas = ((rna * 100) / total).toFixed(2)
        const drs_cess = ((drs_ces * 100) / total).toFixed(2)

        this.setState({
            lineaire_cercle: lineaires,
            surfacique_cercle: surfaciques,
            mise_defens_cercle: mise_defenss,
            maraichage_cercle: maraichages,
            rna_cercle: rnas,
            drs_ces_cercle: drs_cess
        });

    }

    communeChange(value) {
        this.setState({ communeSelected: value });
        const data_suivis_cercle = this.state.data_suivis.filter(c => c['gp_localisation/commune'] === value);

        const lineaire = data_suivis_cercle.filter(c => c['type_activite'] === 'lineaire').length;
        const surfacique = data_suivis_cercle.filter(c => c['type_activite'] === 'surfacique').length;
        const mise_defens = data_suivis_cercle.filter(c => c['type_activite'] === 'mise-defens').length;
        const maraichage = data_suivis_cercle.filter(c => c['type_activite'] === 'maraichage').length;
        const rna = data_suivis_cercle.filter(c => c['type_activite'] === 'rna').length;
        const drs_ces = data_suivis_cercle.filter(c => c['type_activite'] === 'drs-ces').length;

        const total = lineaire + surfacique + mise_defens + maraichage + rna + drs_ces;

        const lineaires = ((lineaire * 100) / total).toFixed(2)
        const surfaciques = ((surfacique * 100) / total).toFixed(2)
        const mise_defenss = ((mise_defens * 100) / total).toFixed(2)
        const maraichages = ((maraichage * 100) / total).toFixed(2)
        const rnas = ((rna * 100) / total).toFixed(2)
        const drs_cess = ((drs_ces * 100) / total).toFixed(2)

        this.setState({
            lineaire_commune: lineaires,
            surfacique_commune: surfaciques,
            mise_defens_commune: mise_defenss,
            maraichage_commune: maraichages,
            rna_commune: rnas,
            drs_ces_commune: drs_cess
        });

    }

    printPdf() {
        const input = document.getElementById('Graphique');
        html2canvas(input)
        .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF()
            pdf.addImage(imgData, "JPEG", 5, 5, 200, 0);
            pdf.save("Graphique.pdf");
        })
    }

    printPng() {
        const input = document.getElementById('Graphique');
        html2canvas(input)
        .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            console.log(imgData)
            var a = document.createElement("a")
            a.href = imgData 
            a.download = "Graphique.png"
            a.click()
        })
    }

    render() {

        const activite_region = {
            labels: [
                "Plan. linéaire: " + this.state.lineaire_region + "%",
                "Plan. surfacique: " + this.state.surfacique_region + "%",
                "Mise en défens: " + this.state.mise_defens_region + "%",
                "Maraîchage: " + this.state.maraichage_region + "%",
                "RNA/Plan. Agroforestière: " + this.state.rna_region + "%",
                "DRS/CES: " + this.state.drs_ces_region + "%"
            ],
            datasets: [
                {
                    label: '',
                    data: [
                        this.state.lineaire_region,
                        this.state.surfacique_region,
                        this.state.mise_defens_region,
                        this.state.maraichage_region,
                        this.state.rna_region,
                        this.state.drs_ces_region
                    ],
                    backgroundColor: ['#006400', '#008000', '#32CD32', '#7FFF00', '#00FA9A', '#556B2F']

                }
            ]
        }

        const pie2 = {
            labels: [
                "Plan. linéaire: " + this.state.lineaire_cercle + "%",
                "Plan. surfacique: " + this.state.surfacique_cercle + "%",
                "Mise en défens: " + this.state.mise_defens_cercle + "%",
                "Maraîchage: " + this.state.maraichage_cercle + " %",
                "RNA/Plan. Agroforestière: " + this.state.rna_cercle + "%",
                "DRS/CES: " + this.state.drs_ces_cercle + "%"
            ],
            datasets: [
                {
                    label: '',
                    data: [
                        this.state.lineaire_cercle,
                        this.state.surfacique_cercle,
                        this.state.mise_defens_cercle,
                        this.state.maraichage_cercle,
                        this.state.rna_cercle,
                        this.state.drs_ces_cercle
                    ],
                    backgroundColor: ['#006400', '#008000', '#32CD32', '#7FFF00', '#00FA9A', '#556B2F']

                }
            ]
        }

        const pie3 = {
            labels: [
                "Plan. linéaire: " + this.state.lineaire_commune + "%",
                "Plan. surfacique: " + this.state.surfacique_commune + "%",
                "Mise en défens: " + this.state.mise_defens_commune + "%",
                "Maraîchage: " + this.state.maraichage_commune + "%",
                "RNA/Plan. Agroforestière: " + this.state.rna_commune + "%",
                "DRS/CES: " + this.state.drs_ces_commune + "%"
            ],
            datasets: [
                {
                    label: '',
                    data: [
                        this.state.lineaire_commune,
                        this.state.surfacique_commune,
                        this.state.mise_defens_commune,
                        this.state.maraichage_commune,
                        this.state.rna_commune,
                        this.state.drs_ces_commune
                    ],
                    backgroundColor: ['#006400', '#008000', '#32CD32', '#7FFF00', '#00FA9A', '#556B2F']

                }
            ]
        }

        return (
            <div className="fade-in">
                <div className="row">
                    <div className="col-md-12 col-lg-12" style={{ marginBottom: 12 }}>
                        <div style={{ float: 'right' }}>
                            <button type="button" className="btn btn-outline-primary btn-sm mytooltip" style={{ marginRight: 8 }} onClick={()=>this.printPng()}>
                                <span className="tooltiptext">Exporter en image</span>
                                <i className="fa fa-file-image-o" aria-hidden="true"></i>
                            </button>
                            <button type="button" className="btn btn-outline-primary btn-sm mytooltip" onClick={()=>this.printPdf()}>
                                <span className="tooltiptext">Exporter en pdf</span>
                                <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div className="row" id="Graphique">
                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-header">
                                <span style={{ marginTop: 20 }}>Type d'activité par région en (%) </span>

                                <div className="btn-group float-right" >
                                    <select className="form-control" onChange={(e) => { this.regionChange(e.target.value) }} value={this.state.regionSelected} >
                                        {this.state.regions.map((r, i) => {
                                            return <option value={r.name} key={i}>{r.label}</option>
                                        })}
                                    </select>
                                </div>

                            </div>

                            <div className="card-body">
                                <Pie 
                                    data={activite_region} 
                                    options= {{
                                        tooltips: {
                                            callbacks: {
                                                label: function(tooltipItem, data) { 
                                                    return data.labels[tooltipItem.index];
                                                    //return data.labels[tooltipItem[0].index]; data.datasets[tooltipItem.datasetIndex].label
                                                }
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-header">
                                <span style={{ marginTop: 20 }}>Type d'activité par cercle en (%)</span>

                                <div className="btn-group float-right" >
                                    <select className="form-control" onChange={(e) => { this.cercleChange(e.target.value) }} value={this.state.cercleSelected}>
                                        <option value="">Cercles</option>
                                        {this.state.newCercles.map((c, i) => {
                                            return <option value={c.name} key={i}>{c.label}</option>
                                        })}
                                    </select>

                                    <p></p>
                                </div>


                            </div>

                            <div className="card-body">
                                <Pie 
                                    data={pie2} 
                                    options= {{
                                        tooltips: {
                                            callbacks: {
                                                label: function(tooltipItem, data) { 
                                                    return data.labels[tooltipItem.index];
                                                    //return data.labels[tooltipItem[0].index]; data.datasets[tooltipItem.datasetIndex].label
                                                }
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card">
                            <div className="card-header">
                                <span style={{ marginTop: 20 }}>Type d'activité par commune en (%)</span>

                                <div className="btn-group float-right" >
                                    <select className="form-control" onChange={(e) => { this.communeChange(e.target.value) }} value={this.state.communeSelected}>
                                        <option value="">Commune</option>
                                        {this.state.newCommunes.map((c, i) => {
                                            return <option value={c.name} key={i}>{c.label}</option>
                                        })}
                                    </select>

                                    <p></p>
                                </div>


                            </div>

                            <div className="card-body">
                                <Pie 
                                    data={pie3} 
                                    options= {{
                                        tooltips: {
                                            callbacks: {
                                                label: function(tooltipItem, data) { 
                                                    return data.labels[tooltipItem.index];
                                                    //return data.labels[tooltipItem[0].index]; data.datasets[tooltipItem.datasetIndex].label
                                                }
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}
