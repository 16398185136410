import React from 'react'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import GoogleMapReact from 'google-map-react'
import Marker from '../components/Marker'

const colors = ["Blue ", "Green", "Red", "Orange", "Violet", "Indigo", "Yellow", "Blue ", "Green", "Red", "Orange", "Violet", "Indigo", "Yellow", "Blue ", "Green", "Red", "Orange", "Violet", "Indigo", "Yellow", "Blue ", "Green", "Red", "Orange", "Violet", "Indigo", "Yellow", "Blue ", "Green", "Red", "Orange", "Violet", "Indigo", "Yellow", "Blue ", "Green", "Red", "Orange", "Violet", "Indigo", "Yellow"]

export default class Carte extends React.Component {
    constructor(props) {
        super(props)
        this.state = { suivis: [] }
    }

    componentDidMount() { this.setState({ suivis: this.props.data }) }

    printPdf() {
        const input = document.getElementById('Carte');
        html2canvas(input, {
            useCORS: true,
            allowTaint: false,
            ignoreElements: (node) => { return node.nodeName === 'IFRAME' }
          })
        .then((canvas) => {
            const imgData = canvas.toDataURL('image/png')
            const pdf = new jsPDF()
            pdf.addImage(imgData, "JPEG", 5, 5, 200, 0)
            pdf.save("Carte.pdf")
        })
    }

    printPng() {
        const input = document.getElementById('Carte');
        html2canvas(input,{
            useCORS: true,
            allowTaint: false,
            ignoreElements: (node) => { return node.nodeName === 'IFRAME' }
          })
        .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            var a = document.createElement("a")
            a.href = imgData 
            a.download = "Carte.png"
            a.click()
        })

    }

    render() {
        const { suivis } = this.state

        return (
            <div className="fade-in">
                <div className="row">
                    <div className="col-md-12 col-lg-12" style={{marginBottom: 12}}>
                        <div style={{float: 'right'}}>
                            <button type="button" className="btn btn-outline-primary btn-sm mytooltip" style={{marginRight: 8}} onClick={()=>this.printPng()}>
                                <span className="tooltiptext">Exporter en image</span>
                                <i className="fa fa-file-image-o" aria-hidden="true"></i>
                            </button>
                            <button type="button" className="btn btn-outline-primary btn-sm mytooltip" onClick={()=>this.printPdf()}>
                                <span className="tooltiptext">Exporter en pdf</span>
                                <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-header">Les sites sur maps</div>

                            <div className="card-body" style={{padding: 2}} id="Carte">
                                <div style={{ height: 600, width: '100%' }}>
                                    <GoogleMapReact
                                        // AIzaSyDUFBMEPB7cEOJEsoHjTodW81ZEJsEszks Ahmed key
                                        // AIzaSyDygSR4sFHe11DGVGHo9-P2QW9IDSwLqV8 agcc key
                                        bootstrapURLKeys={{ key: 'AIzaSyDUFBMEPB7cEOJEsoHjTodW81ZEJsEszks' }}
                                        defaultCenter={{ lat: 12.38843, lng: -7.95390 }}
                                        defaultZoom={6}
                                    >
                                        {
                                            suivis.map((s, i) => {
                                                return (
                                                    <Marker
                                                        key={i}
                                                        name={s['gp_enqueteur/code_site']}
                                                        color={colors[i]}
                                                        lat={s['_geolocation'][0]}
                                                        lng={s['_geolocation'][1]}
                                                    />
                                                )
                                            })
                                        }
                                    </GoogleMapReact>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}