import axios from 'axios';

const baseUrl = '/api/v1'
const username = process.env.REACT_APP_USERNAME
const password = process.env.REACT_APP_PASSWORD

export default class Api {

    get = async() =>{
        return axios.get(`${baseUrl}/data/447066`, {  auth: { username: username, password: password} })
    }
    getQuery = async(query) =>{
        return axios.get(`${baseUrl}/data/447066?query=${query}`, {  auth: { username: username, password: password } })
    }
    getRegion = async() =>{
        return axios.get(`${baseUrl}/forms/447066/form.json`, {  auth: { username: username, password: password } })
    }

    getRapports = async() =>{
        return axios.get('https://agcc2.mylogineo.com/api/rapports')
    }


}