import React from 'react'
import { Link } from 'react-router-dom';
const active = 'nav-link active'
const defaut = 'nav-link'

export default class Layout extends React.Component {
    constructor(props){
        super(props)
        this.state = { pathname: window.location.pathname }
    }
    handleChangeRoute(pathname){
        if(pathname !== this.state.pathname)
            this.setState({ pathname })
    }
    render() {
        const { pathname } = this.state
        return (
            <div>
                <ul className="nav nav-tabs">
                    <li className="nav-item">
                        <Link className={pathname === '/tableau' || pathname === '/' ? active : defaut } to='/tableau' onClick={()=>this.handleChangeRoute('/tableau')}> Tableau </Link>
                    </li>
                    <li className="nav-item">
                        <Link className={pathname === '/graphique' ? active : defaut } to='/graphique' onClick={()=>this.handleChangeRoute('/graphique')} > Graphique </Link>
                    </li>
                    <li className="nav-item">
                        <Link className={pathname === '/carte' ? active : defaut } to='/carte' onClick={()=>this.handleChangeRoute('/carte')} > Carte </Link>
                    </li>
                    <li className="nav-item">
                        <Link className={pathname === '/rapports' ? active : defaut } to='/rapports' onClick={()=>this.handleChangeRoute('/rapports')} > Rapports </Link>
                    </li>
                </ul>
            </div>
        );
    }
}